import { template as template_c8f001163e1d4114a86807f09df94ba4 } from "@ember/template-compiler";
const FKLabel = template_c8f001163e1d4114a86807f09df94ba4(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
