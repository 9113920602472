import { template as template_c4cc92f5dc8c4ad8918580ab0e048911 } from "@ember/template-compiler";
import { on } from "@ember/modifier";
const BulkSelectCell = template_c4cc92f5dc8c4ad8918580ab0e048911(`
  <td class="bulk-select topic-list-data">
    <label for="bulk-select-{{@topic.id}}">
      <input
        {{on "click" @onBulkSelectToggle}}
        checked={{@isSelected}}
        type="checkbox"
        id="bulk-select-{{@topic.id}}"
        class="bulk-select"
      />
    </label>
  </td>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectCell;
