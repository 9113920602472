import { template as template_c0c51d689a864a2bba96f929c5e26593 } from "@ember/template-compiler";
const SidebarSectionMessage = template_c0c51d689a864a2bba96f929c5e26593(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
